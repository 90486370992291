@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@font-face {
  font-family: brandon;
  src: url('../../public/fonts/Brandon_med.otf');
}
@font-face {
  font-family: brandon;
  src: url('../../public/fonts/Brandon_bld.otf');
  font-weight: bold;
}
@font-face {
  font-family: brandon;
  src: url('../../public/fonts/Brandon_med_it.otf');
  font-style: italic;
}
@import '../site/css/site';
@import '../utils/css/utils';
@import '../utils/css/cookie-consent';

