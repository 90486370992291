
body, html {
  height: 100%;
  background-color: #FFFFFF;
  font-family: Ubuntu, sans-serif;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .9);
}

.navbar-brand {
  img {
    max-height: 30px;
    width: auto;
  }
}

.navbar-light .navbar-nav .nav-link {
  font-weight: bold;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.3; /* Firefox */
}

.header-style {
  border-bottom: 2px solid #b7b7b7;
}

.style-card {

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  background-color: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  border-radius: 3px;

  &-image-container {
    text-align: center;
    min-height: 15rem;
    max-height: 15rem;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    justify-items: center;
    position: relative;

    &-image {
      object-fit: cover;
      vertical-align: center;
    }

  }

  &-body {
    text-align: center;
    padding: 1rem;

    &-name {
      min-height: 3rem;
    }

    &-date {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

}

#reservation-alert-countdown {
  font-size: 1.2rem;
}

#ticket-list {
  .sort, .btn {
    cursor: pointer;
  }

  .modal-footer {
    justify-content: normal;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  .material-icons {
    vertical-align: sub;
    padding-bottom: 0;
  }

}

h1, h2 {
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 1.2rem;
}

.material-icons {
  padding-bottom: 0.3rem;
}

#event-information {

  font-size: 1.2rem;

  ul {
    list-style: none;
  }

  .event-information-title {
    font-size: 1.5rem;
  }

}


#organizer-payout-report {
  .sort, .btn {
    cursor: pointer;
  }

  .modal-footer {
    justify-content: normal;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  .material-icons {
    vertical-align: sub;
    padding-bottom: 0;
  }

}

.clickable-td {
  cursor: pointer;
}

.clickable-li {
  cursor: pointer;
}

.not-required {
  opacity: 0.45;
}

.auto-height {
  height: auto;
}

.restriction-code-ticket-category {
  border: 2px solid #46a0ff;
}

.html-new-line {
  white-space: pre-line;
}

.breadcrumb {
  background-color: #ffffff;
}

#footer {
  font-size: 14px;
  border-top: 2px solid #b7b7b7;

  a {
    color: #343a40;
  }

}

.sidebar ul.nav > li > button.config-option-features-button {
  color: rgba(255, 255, 255, 0.2);
  display: block;
}

.sidebar ul.nav > li > button.config-option-features-button:hover {
  color: #ffffff;
  text-decoration: none;
}

.event-tabs button.config-option-features-button {
  color: rgba(0, 123, 255, 0.3);
  display: block;
}

.event-tabs button.config-option-features-button:hover {
  color: #007bff;
  text-decoration: none;
}

#content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#content-body {
  flex-grow: 1;
}

.container, .container-sm {
  @media (max-width: 768px) {
    max-width: unset;
  }
}

.frontpage {

  font-family: brandon;

  .content-wrapper {
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    text-align: center;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: bold;
  }

  .header {
    font-size: 1.3rem;
    color: #FFFFFF;
    background-image: url('/public/images/frontpage/header-background.jpg');
    position: relative;

    &-overlay {
      background-color: rgba(72, 32, 77, 0.9);
    }

    &-icon {
      margin-top: -70px;
      margin-left: auto;
      margin-right: auto;
      width: 100px;
      height: auto;
      display: block;
      z-index: 1;
      position: relative;
    }
  }

  .gift {
    margin-top: 100px;

    @media (max-width: 768px) {
      margin-top: 10px;
    }

    h1 {
      font-size: 6rem;
      @media (max-width: 768px) {
        font-size: 4rem;
        max-width: 540px;
        margin: 20px auto;
      }
    }

    &-image {
      position: relative;

      &-phone {
        position: relative;
        top: 0;
        right: 0;
        z-index: 2000;
      }

      &-play-button {
        position: absolute;
        top: 250px;
        right: 160px;
        z-index: 3000;
        width: 75px;
        height: auto;
        cursor: pointer;

        @media (min-width: 768px) {
          top: 350px;
          right: 250px;
        }
      }
    }
  }

  .benefits {
    background-image: url('/public/images/frontpage/benefits-background.jpg');
    position: relative;

    &-overlay {
      background-color: rgba(72, 32, 77, 0.9);
    }

    ul {
      list-style-image: url('/public/images/frontpage/checkmark.png');
      color: #FFFFFF;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .about {
    p {
      font-size: 1.2rem;
      @media (max-width: 768px) {
        max-width: 540px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  #videoModal {
    width: unset;
    z-index: 4000;

    .modal-body {
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }

}

